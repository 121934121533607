import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ModalPopups } from '@enums';
import { ProgressContainer } from '@components';
import { R, Modal, SuccessMessage, ErrorMessage } from '@dev2t/react-ui-kit/src/components';
import { ordersCy, userAccounts, app } from '@redux';
import { useJ2txCommission } from '@hooks';
import { getUSDTProtocol } from '@global';
import { formValueSelector } from 'redux-form';
import { constants } from '@config';
import {
	useAvailableCurrencies,
	J2TXWithdrawalAmountForm,
	J2TXWithdrawalConfirmForm,
	J2TXWithdrawalSelectMethod,
} from './components';
import { CONFIRMATION_TYPE } from '@shared/RequireConfirmationCodeItem/RequireConfirmationCodeItem';

const J2TX_MT5_WITHDRAWAL_FORM_ID = ModalPopups.J2TX_MT5_WITHDRAWAL;
const J2TXMT5WithdrawalModal = ({ onClose }) => {
	const language = useSelector(app.selectors.language);
	const [step, setStep] = useState('selectmethod');
	const loading = useSelector(state => userAccounts.selectors.loading(state) || userAccounts.selectors.accountInfo(state).loading);

	// commission for form validation
	const EMoneyOut = useSelector(state => formValueSelector(ModalPopups.J2TX_MT5_WITHDRAWAL)(state, 'EMoneyOut'));
	const NonOrderData = useSelector(state => formValueSelector(ModalPopups.J2TX_MT5_WITHDRAWAL)(state, 'NonOrderData'));
	const commission = useJ2txCommission(EMoneyOut?.AccountCurrency, getUSDTProtocol(EMoneyOut?.TargetAccount));

	// provide initial values into the form
	const { tradeCode, clearerFirm } = useSelector(userAccounts.selectors.modals)[ModalPopups.J2TX_MT5_WITHDRAWAL];

	const platform = useSelector(userAccounts.selectors.accounts)
		.find(x => x.TradeCode === tradeCode && x.ClearingFirm === clearerFirm)
		.Platform;

	const initialValues = {
		NonOrderData: {
			ConfirmationCode: null,
			TradeCode: tradeCode,
			ConfirmationType: CONFIRMATION_TYPE.Sms,
		},
		EMoneyOut: {
			SelectedAccount: `${tradeCode};${platform}`,
			IsAllOut: false,
			IsCrypto: true,
			Urgently: false,
			Summ: 0,
			Currency: undefined,
			AccountCurrency: undefined,
			Comment: '',
			Reason: constants.MoneyOutReason.NeedMoney,
			ReasonComment: '',
			SelectTarget: undefined,
			TargetAccount: '',
		},
	};

	const handleGotoPage = page => (result, dispatch, props) => setStep(page);
	const existCurrency = useAvailableCurrencies(tradeCode, true).length > 0;
	const isRestrictedWithdrawal = NonOrderData?.ConfirmationType === CONFIRMATION_TYPE.Email && language === 'zh';
	return (
		<Modal
			title={<R id="shared_withdrawal_funds" defaultValue="Withdrawal Funds" />}
			onClose={onClose}
			maxWidth="sm"
		>
			<ProgressContainer submitting={loading}>
				<div>
					{step === 'succeeded'
						&& (
							<SuccessMessage
								onClick={onClose}
								text={isRestrictedWithdrawal ?
									<R
										id="common_wallet_temporary_restricted"
										defaultValue="There is a temporary (72 hours) restriction on withdrawal of funds on your account for security purposes due to the fact that the order was confirmed by a code from Email."
									/> :
									<R id="common_crypto_wallet_completed" defaultValue="Withdrawal order completed" />}
							/>
						)}
					{step === 'failed'
						&& (
							<ErrorMessage
								onClick={onClose}
								text={<R id="common_crypto_wallet_failed" defaultValue="Could not complete order" />}
							/>
						)}
					{step === 'selectmethod' && !existCurrency
						&& (
							<ErrorMessage
								onClick={onClose}
								text={(
									<R
										id="withdraw_no_crypto_in_your_ballance"
										defaultValue="There are no withdrawable cryptocurrencies on the selected account"
									/>
								)}
							/>
						)}
					{step === 'selectmethod' && existCurrency
						&& (
							<J2TXWithdrawalSelectMethod
								destroyOnUnmount={false}
								forceUnregisterOnUnmount
								form={J2TX_MT5_WITHDRAWAL_FORM_ID}
								initialValues={initialValues}
								enableReinitialize
								onSubmit={handleGotoPage('amountform')}
								onSuccess={() => setStep('amountform')}
								onFail={() => setStep('failed')}
								isMt5Global={true}
							/>
						)}
					{step === 'amountform'
						&& (
							<J2TXWithdrawalAmountForm
								form={J2TX_MT5_WITHDRAWAL_FORM_ID}
								commission={commission}
								isMt5Global={true}
								destroyOnUnmount={false}
								onSubmit={handleGotoPage('confirmform')}
								onSuccess={() => setStep('confirmform')}
								onFail={() => setStep('failed')}
							/>
						)}
					{step === 'confirmform'
						&& (
							<J2TXWithdrawalConfirmForm
								form={J2TX_MT5_WITHDRAWAL_FORM_ID}
								destroyOnUnmount={false}
								onSubmit={ordersCy.actions.cyWithdrawalEmoneyForm}
								onSuccess={() => setStep('succeeded')}
								onFail={() => setStep('failed')}
							/>
						)}
				</div>
			</ProgressContainer>
		</Modal>
	);
};

export default J2TXMT5WithdrawalModal;
