import React from 'react';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { ModalPopups } from '@enums';
import { userAccounts } from '@redux';
import {
	J2txCurrencyChangeModal,
	ReportsModal,

	J2txTransferModal,

	J2TXMt5WithdrawalModal,
	J2TXWalletWithdrawalModal,
	CYChangeMtPasswordModal,
} from './components';

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
	actions: {
		hideUserAccountsModal: modalId => dispatch(userAccounts.actions.hideUserAccountsModal(modalId)),
	},
});

class UserAccountsModal extends React.Component {
	getUserAccountsModal = item => {
		// !!! Modals must be in current order !!!

		switch (item) {
			
			case ModalPopups.REPORTS:
				return (
					<ReportsModal
						key={`${ModalPopups.REPORTS}Modal`}
						onClose={() => this.closeModal(ModalPopups.REPORTS)}
					/>
				);

			case ModalPopups.J2TX_CHANGE_CURRENCY:
				return (
					<J2txCurrencyChangeModal
						key={`${ModalPopups.REPORTS}Modal`}
						onClose={() => this.closeModal(ModalPopups.J2TX_CHANGE_CURRENCY)}
					/>
				);

//==================================================================================================//
			
			case ModalPopups.TRANSFER_WALLET_TO_MT5:
				return (
					<J2txTransferModal
						isMt5Global={true}
						key={`${ModalPopups.TRANSFER_WALLET_TO_MT5}Modal`}
						onClose={() => this.closeModal(ModalPopups.TRANSFER_WALLET_TO_MT5)}
					/>
				);

			case ModalPopups.TRANSFER_MT5_TO_WALLET:
				return (
					<J2txTransferModal
						key={`${ModalPopups.TRANSFER_MT5_TO_WALLET}Modal`}
						onClose={() => this.closeModal(ModalPopups.TRANSFER_MT5_TO_WALLET)}
					/>
				);

			case ModalPopups.TRANSFER_WALLET_TO_STAKING:
				return (
					<J2txTransferModal
						key={`${ModalPopups.TRANSFER_WALLET_TO_STAKING}Modal`}
						onClose={() => this.closeModal(ModalPopups.TRANSFER_WALLET_TO_STAKING)}
					/>
				);

			case ModalPopups.TRANSFER_STAKING_TO_WALLET:
				return (
					<J2txTransferModal
						key={`${ModalPopups.TRANSFER_STAKING_TO_WALLET}Modal`}
						onClose={() => this.closeModal(ModalPopups.TRANSFER_STAKING_TO_WALLET)}
					/>
				);

//==================================================================================================//
			
			case ModalPopups.J2TX_MT5_WITHDRAWAL:
				return (
					<J2TXMt5WithdrawalModal
						key={`${ModalPopups.J2TX_MT5_WITHDRAWAL}Modal`}
						onClose={() => this.closeModal(ModalPopups.J2TX_MT5_WITHDRAWAL)}
					/>
				);

			case ModalPopups.J2TX_WALLET_WITHDRAWAL:
				return (
					<J2TXWalletWithdrawalModal
						key={`${ModalPopups.J2TX_WALLET_WITHDRAWAL}Modal`}
						onClose={() => this.closeModal(ModalPopups.J2TX_WALLET_WITHDRAWAL)}
					/>
				);

//==================================================================================================//

			case ModalPopups.CY_CHANGE_MT_PASSWORD:
				return (
					<CYChangeMtPasswordModal
						key={`${ModalPopups.CY_CHANGE_MT_PASSWORD}Modal`}
						onClose={() => this.closeModal(ModalPopups.CY_CHANGE_MT_PASSWORD)}
					/>
				);
			default:
				return null;
		}
	}

	closeModal = id => this.props.actions.hideUserAccountsModal(id);

	render() {
		const { location } = this.props;

		const modals = location.hash && location.hash.substr(1).split('/');
		const topModal = modals && modals.length > 0 && this.getUserAccountsModal(modals && modals.pop());

		return topModal;
	}
}

UserAccountsModal.propTypes = {
	actions: PropTypes.object.isRequired,
};

export default compose(
	withRouter,
	connect(mapStateToProps, mapDispatchToProps),
)(UserAccountsModal);
