import React from 'react';
import compose from 'recompose/compose';
import { constants } from '@config';
import PropTypes from 'prop-types';
import { useDispatch, connect, useSelector } from 'react-redux';
import classNames from 'classnames';
import {
	reduxForm,
	Field,
	formValueSelector,
} from 'redux-form';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';

import {
	WithdrawAmount,
	FormRadioGroup,
	FormCheckbox,
	FormSelect,
	Divider,
	Button,
	Radio,
} from '@components';
import { FormInput, useTranslate } from '@dev2t/react-ui-kit/src';

import { R } from '@dev2t/react-ui-kit/src/components';
import { useBalance } from '@hooks';
import { getUSDTProtocol } from '@global';
import { userAccounts } from '@redux';
import { template } from '@templates';

const useStyles = makeStyles(theme => ({
	root: {
		position: 'relative',
	},
	row: {
		display: 'flex',
	},
	rowAlignEnd: {
		display: 'flex',
		alignItems: 'self-end',
	},
	inputItem: {
		marginTop: '20px',
	},
	divider: {
		marginTop: '30px',
		marginBottom: '20px',
	},
	logo: {
		padding: '16px 16px 4px 16px',
		position: 'relative',
		width: '80px',
		height: '44px',
	},
	inputContainer: {
		display: 'flex',
		width: '100%',
		...theme.customstyles.title,
	},
	account: {
		width: '100%',
		textAlign: 'left',
	},
	button: {
		...theme.mixins.stickyButton,
	},
	preFieldLabel: {
		margin: '10px 24px 0px 24px',
		lineHeight: '21px',
		fontSize: '14px',
		color: theme.palette.text.secondary,
	},
	staticText: {
		margin: '10px 24px 10px 24px',
		lineHeight: '21px',
		fontSize: '14px',
		color: theme.palette.text.secondary,
	},
	fieldLeftAdditionalMargin: {
		marginLeft: '24px',
	},
	summCurrency: {
		fontSize: '2em',
		fontWeight: '700',
		paddingTop: '16px',
		lineHeight: '60px',
	},
	mb: {
		marginBottom: '24px',
	},
	fixWithdrawAmount: {
		paddingLeft: '20px',
		marginTop: '-10px',
		marginLeft: '-24px',
	},
}));

function J2TXWithdrawalCryptoWalletAmountForm({
	form, commission, handleSubmit, invalid, submitting, isMt5Global
}) {
	const theme = useTheme();
	const classes = useStyles();
	const translate = useTranslate();

	const EMoneyOut = useSelector(state => formValueSelector(form)(state, 'EMoneyOut'));
	const { TradeCode } = useSelector(state => formValueSelector(form)(state, 'NonOrderData'));

	const balance = useBalance(TradeCode, isMt5Global);
	const loadingAccounts = useSelector(userAccounts.selectors.accountsInfoLoading);
	const selectedCurrency = EMoneyOut.AccountCurrency;

	const availableBalance = balance
		?.find(x => x.CurrencyCode === selectedCurrency && x.IsAllowedOut)
		?.Balance;

	const dispatch = useDispatch();
	const refreshBalance = () => dispatch(userAccounts.actions.getAllAccountsInfo.request());

	const label = (`${commission?.label}. \n` ?? '') + translate('common_amount_available_to_withdraw', 'Amount available to withdraw');

	return (
		<form
			onSubmit={handleSubmit}
			className={classes.root}
		>
			<div className={classes.accountContainer}>
				<div className={classes.row}>
					<div className={classes.logo}>
						<template.firmLogo />
					</div>
					<Field component={FormSelect} name="NonOrderData.TradeCode" label={translate('common_account', 'Account')} disabled>
						<MenuItem value={TradeCode}>
							<div className={classes.inputContainer}>
								<div className={classes.account}>{TradeCode}</div>
							</div>
						</MenuItem>
					</Field>
				</div>
			</div>
			<div className={classes.divider}>
				<Divider />
			</div>
			<div className={classes.inputItem}>
				<p className={classes.preFieldLabel}>
					<R id="moneyout_emoney_currency_title" defaultValue="Currency of your wallet/account/card" />
				</p>
				<div className={classes.fieldLeftAdditionalMargin}>
					<Field
						name="EMoneyOut.AccountCurrency"
						orientation="horizontal"
						component={FormRadioGroup}
					>
						<Radio value={EMoneyOut.AccountCurrency} label={EMoneyOut.AccountCurrency} />
					</Field>
				</div>
			</div>
			<div className={classes.inputItem}>
				<Field
					component={FormInput}
					validationMessageMode="afterAdditional"
					name="EMoneyOut.TargetAccount"
					label={translate('orders_crypto_targetaccount', 'Wallet Address')}
				/>
			</div>
			{EMoneyOut && EMoneyOut.SelectTarget === 'RIPPLE'
				&& (
					<div className={classes.inputItem}>
						<Field
							component={FormInput}
							name="EMoneyOut.DestinationTag"
							label={translate('funds_deposit_payment_ripple_address_tag', 'Destination tag')}
						/>
					</div>
				)}
			<div className={classes.inputItem}>
				<div className={classes.staticText}>
					<R
						id="j2tx_cryptocurrency_operations_warning"
						defaultValue="Note that transactions with cryptocurrency are irreversible.
							Be careful when specifying addresses of cryptocurrency wallets for transfers.
							Use coping instead of typing.
							J2TX does not bear any responsibility in case of an error in the address specified when transferring."
					/>
				</div>
			</div>
			<div className={classes.inputItem}>
				<Field
					component={FormInput}
					name="EMoneyOut.Comment"
					label={translate('shared_comment', 'Comment')}
					multiline
				/>
			</div>
			<div className={classNames(classes.inputItem, classes.divider)}>
				<WithdrawAmount
					name="EMoneyOut.Summ"
					validationMessageMode="afterAdditional"
					loading={loadingAccounts}
					availableAmount={availableBalance}
					currency={EMoneyOut.AccountCurrency}
					withdrawLabelText={label}
					allowIconButtons={false}
					prefix=""
					onClick={refreshBalance}
					classes={{ inputItem: classes.fixWithdrawAmount }}
					label={translate('shared_amount', 'Amount')}
				/>
			</div>
			<div className={classes.inputItem}>
				<Field
					component={FormCheckbox}
					name="NonOrderData.ConfirmAccountOwner"
					label={(
						<R
							id="common_confirm_account_owner"
							defaultValue="I confirm that the monetary funds shall be transferred to my personal bank account."
						/>
					)}
				/>
			</div>
			<div className={classNames(classes.inputItem, classes.mb)}>
				<Field
					component={FormCheckbox}
					name="NonOrderData.ConfirmDataCorrect"
					label={(
						<R
							id="common_moneyout_confirm_data_correct"
							defaultValue="I confirm that all the details specified in the order are true."
						/>
					)}
				/>
			</div>
			<Button
				fullWidth
				type="submit"
				variant="contained"
				color="primary"
				disabled={invalid || submitting || loadingAccounts}
			>
				{submitting
					? (
						<CircularProgress
							style={{ color: theme.palette.primary.contrastText }}
							size={18}
						/>
					)
					: <R id="common_btn_continue" defaultValue="Continue" />}
			</Button>
		</form>
	);
}

J2TXWithdrawalCryptoWalletAmountForm.propTypes = {
	form: PropTypes.string.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	// commission: PropTypes.object.isRequired,

	invalid: PropTypes.bool.isRequired,
	submitting: PropTypes.bool.isRequired,
};

function withValidation(Component) {
	function ComponentWithValidation(props) {
		const translate = useTranslate();

		const amountFormValidate = (formvalues, props) => {
			const { accountsInfo, commission } = props;
			const values = formvalues;
			const balance = accountsInfo.find(x => x.TradeCode === values.NonOrderData.TradeCode).Balance;
			const errors = {};
			if (!values) {
				return errors;
			}

			if (values.EMoneyOut) {
				const selectedCurrency = values.EMoneyOut.AccountCurrency;
				const availableBalance = [...balance.CryptoCurrencies, ...balance.Currencies]
					.find(x => x.CurrencyCode === selectedCurrency)
					.Balance;
				errors.EMoneyOut = {};

				if (!values.EMoneyOut.Summ) {
					errors.EMoneyOut.Summ = translate('shared_required', 'Required');
				}
				if (values.EMoneyOut.Summ > availableBalance) {
					errors.EMoneyOut.Summ = translate('shared_not_enough_money',
						'Insufficient funds for withdrawal');
				}

				if (values.EMoneyOut.Summ < 0) {
					errors.EMoneyOut.Summ = translate('amount_must_be_greater_than_zero', 'Amount must be greater than 0');
				}

				if (values.EMoneyOut.Summ < commission?.flat) {
					errors.EMoneyOut.Summ = translate('amount_shoud_be_greater_than_commission',
						'Amount should be greater than commission');
				}
				if (!values.EMoneyOut.TargetAccount) {
					errors.EMoneyOut.TargetAccount = translate('shared_required', 'Required');
				}

				if (selectedCurrency === 'USDT') {
					const protocol = getUSDTProtocol(values.EMoneyOut.TargetAccount);
					if (protocol !== 'ERC20' && protocol !== 'TRC20') {
						errors.EMoneyOut.TargetAccount = translate('bad_usdt_address', 'Only ERC-20 or TRC-20 wallets allowed');
					}
				}

				if (selectedCurrency === 'USDC') {
					const protocol = getUSDTProtocol(values.EMoneyOut.TargetAccount);
					if (protocol !== 'ERC20') {
						errors.EMoneyOut.TargetAccount = translate('bad_usdc_address', 'Only ERC-20 wallets allowed');
					}
				}

				if (selectedCurrency === 'BTC') {
					const isMatch = values.EMoneyOut.TargetAccount.match(constants.regExpBitcoinWallet);
					if (!isMatch) {
						errors.EMoneyOut.TargetAccount = translate('bad_address', 'Bad address');
					}
				}

				if (!values.EMoneyOut.DestinationTag) {
					errors.EMoneyOut.DestinationTag = translate('shared_required', 'Required');
				}
				if (!values.EMoneyOut.Comment) {
					errors.EMoneyOut.Comment = translate('shared_required', 'Required');
				}
			}
			if (values.NonOrderData) {
				errors.NonOrderData = {};
				if (!values.NonOrderData.ConfirmAccountOwner) {
					errors.NonOrderData.ConfirmAccountOwner = translate('shared_required', 'Required');
				}
				if (!values.NonOrderData.ConfirmDataCorrect) {
					errors.NonOrderData.ConfirmDataCorrect = translate('shared_required', 'Required');
				}
			}
			return errors;
		};
		return <Component {...props} validate={amountFormValidate} />;
	}
	return ComponentWithValidation;
}

export default compose(
	connect(state => ({
		accountsInfo: userAccounts.selectors.accountsInfo(state),
	})),
	withValidation,
	reduxForm({
		onSubmitSuccess: (result, dispatch, props) => {
			if (props.onSuccess && (typeof props.onSuccess === 'function')) {
				props.onSuccess();
			}
		},
		onSubmitFail: (errors, dispatch, submitError, props) => {
			// eslint-disable-next-line no-underscore-dangle
			if (errors._error && props.onFail) {
				props.onFail();
			}
		},
	}),
)(J2TXWithdrawalCryptoWalletAmountForm);
