import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import compose from 'recompose/compose';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import { constants, env } from '@config';
import { scrollIntoView, translate } from '@global';
import { auth, app } from '@redux';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import { Button, Select, SelectField} from '@components';
import { R, FormInput, clearInterval, setInterval } from '@dev2t/react-ui-kit/src';

export const RequireConfirmationCodeReason = {
	MoneyTransferApply: 'MoneyTransferApply',
	Confirmation: 'Confirmation',
	NontradingOrderApply: 'NontradingOrderApply',
	MoneyTransferApply: 'MoneyTransferApply',
	MoneyOutApply: 'MoneyOutApply',
}

const styles = {
	root: {
		display: 'block',
		position: 'relative',
	},
	progress: {
		display: 'flex',
		justifyContent: 'center',
	},
	confirmLink: {
		marginTop: '16px',
		paddingLeft: '24px',
	},
	link: {
		textTransform: 'uppercase',
	},
	button: {
		paddingTop: '24px',
	},
};

export const CONFIRMATION_TYPE = {
	Email: 'email',
	Sms: 'sms',
};

const getConfirmationTypes = () => [
	{value: CONFIRMATION_TYPE.Sms, text: translate('confirm_type_sms', 'SMS')},
	{value: CONFIRMATION_TYPE.Email, text: translate('confirm_type_email', 'Email')},
];

const mapStateToProps = (state, ownProps) => {
	const language = app.selectors.language(state);
	return {
		language,
	};
};

const mapDispatchToProps = dispatch => ({
	actions: {
		sendConfirmationCode: (phone, reason) => dispatch(auth.actions.sendConfirmationCodeRequest(phone, reason || 'Confirmation')),
		sendEmailConfirmationCode: (email, reason)  => dispatch(auth.actions.sendEmailConfirmationCodeRequest(email, reason || 'Confirmation')),
	},
});

class RequireConfirmationCodeItem extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			hasConfirmationCode: false,
			showPassword: false,
			password: '',
			timer: 0,
			intervalId: null,
			localConfirmationTypeValue: CONFIRMATION_TYPE.Sms, // TODO: избавиться от локального стейта в WEBCAB-8495
		};
	}

	componentWillUnmount() {
		// use intervalId from the state to clear the interval
		clearInterval(this.state.intervalId);
	}


	componentDidUpdate() {
		const {
			hasConfirmationCode,
			timer,
		} = this.state;

		if (hasConfirmationCode && timer === 120) {
			scrollIntoView('js-confirm-code-place');
		}
	}

	onRequestVerificationCode = () => {
		const {
			actions,
			reason,
			onSendCode,
			confirmationTypeValue,
		} = this.props;
		const { localConfirmationTypeValue } = this.state;
		
		const byemail = [localConfirmationTypeValue, confirmationTypeValue].some(type => type === CONFIRMATION_TYPE.Email);

		if (onSendCode && (typeof onSendCode === 'function')) {
			onSendCode(null, reason, byemail);
		} else {
			if (byemail) {
				actions.sendEmailConfirmationCode(null, reason);
			} else {
				actions.sendConfirmationCode(null, reason);
			}
		}

		this.startTimer();
	}

	onChangeLocalConfirmationTypeValue = (event) => {
		this.setState({ localConfirmationTypeValue: event.target.value })
	}

	handleClickShowSmsCode = () => this.setState({ showPassword: !this.state.showPassword });

	startTimer = () => {
		if (this.state.intervalId) {
			clearInterval(this.state.intervalId);
		}

		const newTimerIntervalId = setInterval(this.updateTimer, 1000);
		this.setState({
			hasConfirmationCode: true,
			timer: 120,
			intervalId: newTimerIntervalId,
		});
	}

	updateTimer = () => {
		if (this.state.timer === 0) {
			clearInterval(this.state.intervalId);
		} else {
			this.setState({ timer: this.state.timer - 1 });
		}
	}

	isChangeConfirmationTypeAvailable = () => {
		const { onSendCode, language } = this.props;

		if (onSendCode && typeof onSendCode === 'function') return false;

		if (env.firm === constants.LIME_LME) return true;
		if (env.firm === constants.FIRM_J2TX && language === 'zh') return true;

		return false;
	}

	render() {
		const {
			classes,
			customClasses,
			disableConfirmation,
			confirmationCodeFieldName,
			confirmationTypeFieldName,
			confirmationTypeValue,
			children,
			buttonTitle,
			disabled,
			onSendCode, // for not includes in rest!!!
			...rest
		} = this.props;

		const {
			hasConfirmationCode,
			timer,
			localConfirmationTypeValue,
		} = this.state;

		if (disableConfirmation) {
			return (
				<div className={(customClasses && customClasses.button) || classes.button}>
					{children}
				</div>)
		}

		const showChangeType = this.isChangeConfirmationTypeAvailable();

		return (
			<React.Fragment>
				<div id="js-confirm-code-place" className={(customClasses && customClasses.inputItem) || classes.inputItem}>
					{hasConfirmationCode && timer > 0 &&
						<Field
							component={FormInput}
							name={confirmationCodeFieldName}
							label={translate("shared_verification_code", "Verification Code")}
							type="text"
							value={this.state.password}
							props={{
								style: {
									textSecurity: `${!this.state.showPassword ? "disc" : ""} `, 
									WebkitTextSecurity: `${!this.state.showPassword ? "disc" : ""}`,
									MozTextSecurity: `${!this.state.showPassword ? "disc" : ""}`
								}
							}}
							{...rest}
							endAdornment={
								<InputAdornment position="end">
									<IconButton
										aria-label="Toggle password visibility"
										onClick={this.handleClickShowSmsCode}
									>
										{this.state.showPassword ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							}
						/>
					}
					<div className={(customClasses && customClasses.confirmLink) || classes.confirmLink}>
						{hasConfirmationCode && showChangeType &&
							<p>
							   <R id="common_not_received_confirm_code"	
							  	  defaultValue="In case you have not received the code, select the sending method and try again."
							   />
							</p>
						}
						{hasConfirmationCode && !showChangeType &&
							<p>
							   <R id="common_not_received_confirm_code_mochange"	
							  	  defaultValue="In case you have not received the code, try again later or contact support."
							   />
							</p>
						}
						{timer > 0 &&
							<p>
								<R id="common_new_code_in_seconds" translateParameters={{ seconds: timer }} defaultValue="You can request new code in {seconds} seconds" />
							</p>
						}
					
						{timer === 0  
						&& showChangeType
						&& hasConfirmationCode && (
							<div>
								{confirmationTypeFieldName ? (
									<SelectField
										label={translate('type_send_confirm_code_title', 'Code submission method')}
										name={confirmationTypeFieldName}
										items={getConfirmationTypes()}
										value={confirmationTypeValue}
									/>
								) : (
									<Select
										value={localConfirmationTypeValue}
										onChange={this.onChangeLocalConfirmationTypeValue}
									>
										{getConfirmationTypes().map(x => (
											<MenuItem value={x.value} key={x.value}>
												{x.text}
											</MenuItem>
										))}
									</Select>
								)}
							</div>
						)}
					</div>
				</div>
				<div className={(customClasses && customClasses.button) || classes.button}>
					{hasConfirmationCode && timer > 0 &&
						<React.Fragment>
							{children}
						</React.Fragment>
					}
					{(timer === 0 || !hasConfirmationCode) &&
						<Button
							fullWidth
							variant="contained"
							color="primary"
							onClick={this.onRequestVerificationCode}
							disabled={disabled}
						>
							{ buttonTitle } 
						</Button>
					}
				</div>
			</React.Fragment>
		);
	}
}

RequireConfirmationCodeItem.propTypes = {
	classes: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
	/**
	 * При указании true не будет запрашиваться подтверждение, т.е. просто будет выведен контент компонента
	 */
	disableConfirmation: PropTypes.bool,
};

RequireConfirmationCodeItem.defaultProps = {
	disableConfirmation: false,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
)(RequireConfirmationCodeItem);
