import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { rates } from '@redux';
import { constants, env } from '@config';
import {
	ProgressContainer,
	ExpansionPanel,
	FormattedTick,
	LogoIcon,
} from '@components';
import { R } from '@dev2t/react-ui-kit/src/components';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import NoDataIcon from '@material-ui/icons/NotInterested';
import { useOnScreen, useMoneyFormat, useAllowedWalletCryptoCurrencies } from '@hooks';

const useStyles = makeStyles(theme => ({
	tableContainer: {
		margin: '30px 0',
	},
	currency: {
		fontWeight: 700,
		fontSize: '12px',
		lineHeight: '14px',
		[theme.breakpoints.down('sm')]: {
			display: 'none',
		},
	},
	headCell: {
		paddingTop: 0,
		paddingBottom: 0,
		border: 'none',
		fontSize: '12px',
		lineHeight: 1.5,
		verticalAlign: 'top',
		boxSizing: 'border-box',

		[theme.breakpoints.down('sm')]: {
			'&:first-of-type': {
				paddingLeft: 0,
			},
		},
	},
	rowCell: {
		paddingTop: '14px',
		paddingBottom: '14px',
		borderBottom: '2px solid #414147',
		boxSizing: 'border-box',
		fontSize: '16px',
		color: theme.palette.text.primary,
		textWrap: 'nowrap',
		[theme.breakpoints.down('sm')]: {
			'&:first-of-type': {
				paddingLeft: '4px',
			},
		},
	},
	noDataCell: {
		border: 'none',
		paddingBottom: 0,
	},
	open: {
		padding: '0 50px 32px',
		[theme.breakpoints.down('sm')]: {
			padding: '0 40px 20px',
		},
	},
	content: {
		overflowX: 'auto',
	},
}));

const DATA_REFRESH_DELAY = 10000;

//symbol === 'USDT' && env.WEBCAB_6561_allow_usdt
const filterStableCoins = ["USDT", "USDC"];

const CryptocurrencyRates = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const cryptoBlockRef = useRef(null);
	const { submitting, tickers } = useSelector(rates.selectors.cryptocurrency);
	const [isOpen, setIsOpen] = useState(true);
	const isOnScreen = useOnScreen(cryptoBlockRef);
	const moneyFormat = useMoneyFormat();

	const allowedWalletCrypto = useAllowedWalletCryptoCurrencies();
	const symbolsToRequest = allowedWalletCrypto
		.filter(symbol => !filterStableCoins.includes(symbol))
		.map(symbol => `${symbol}.jt`);

	const onToggleOpen = () => setIsOpen(open => !open);

	useEffect(() => {
		// на первое открытие, если еще нет курсов
		if (!submitting && tickers.length === 0) {
			dispatch(rates.actions.getCryptocurrencyRates.request(symbolsToRequest));
		}

		// автообновление
		if (isOpen && isOnScreen) {
			const timer = setInterval(() => {
				dispatch(rates.actions.getCryptocurrencyRates.request(symbolsToRequest));
			}, DATA_REFRESH_DELAY);

			return () => clearInterval(timer);
		}
	}, [isOpen, isOnScreen]);

	const header = (
		<Typography variant="h6">
			<R id="common_cryptocurrency_rates" defaultValue="Cryptocurrency rates" />
		</Typography>
	);

	return (
		<TableContainer component={Paper} className={classes.tableContainer} ref={cryptoBlockRef}>
			<ExpansionPanel
				header={header}
				onToggleOpen={onToggleOpen}
				isOpen={isOpen}
				id="cryptocurrencies"
				classes={{
					opened: classes.open,
					content: classes.content,
				}}
			>
				<ProgressContainer submitting={submitting} disableCheckChildren>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell classes={{ root: classes.headCell }}>
									<R
										id="common_cryptocurrency"
										defaultValue="Cryptocurrency"
									/>
								</TableCell>
								<TableCell classes={{ root: classes.headCell }}>
									<R
										id="common_price"
										defaultValue="Price"
									/>
								</TableCell>
								<TableCell classes={{ root: classes.headCell }}>
									<R
										id="price_change"
										defaultValue="Price change"
									/>
								</TableCell>
								<TableCell classes={{ root: classes.headCell }}>
									<R
										id="pricechange_percent"
										defaultValue="Percentage of price change"
									/>
								</TableCell>
								<TableCell classes={{ root: classes.headCell }}>
									BID
								</TableCell>
								<TableCell classes={{ root: classes.headCell }}>
									ASK
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{tickers.length > 0 ? (
								tickers.map(ticker => {
									const isInitialPositive = ticker.PriceChange === 0 ? null : ticker.PriceChange > 0;
									return (
										<TableRow key={ticker.Symbol}>
											<TableCell classes={{ root: classes.rowCell }}>
												<LogoIcon
													currency={ticker.Symbol?.replace('.jt', '')}
													classes={{ currency: classes.currency }}
												/>
											</TableCell>
											<TableCell classes={{ root: classes.rowCell }}>
												{moneyFormat(ticker.PriceCurrent, '$')}
											</TableCell>
											<TableCell classes={{ root: classes.rowCell }}>
												<FormattedTick
													value={ticker.PriceChange}
													isMoney
													compareWithZero
													measureUnit="$"
													initialPositive={isInitialPositive}
												/>
											</TableCell>
											<TableCell classes={{ root: classes.rowCell }}>
												<FormattedTick
													value={+(ticker.PriceChangePercent?.toFixed(2))}
													measureUnit="%"
													compareWithZero
													initialPositive={isInitialPositive}
												/>
											</TableCell>
											<TableCell classes={{ root: classes.rowCell }}>
												<FormattedTick
													value={ticker.PriceBid}
													isMoney
													measureUnit="$"
													initialPositive={isInitialPositive}
												/>
											</TableCell>
											<TableCell classes={{ root: classes.rowCell }}>
												<FormattedTick
													value={ticker.PriceAsk}
													isMoney
													measureUnit="$"
													initialPositive={isInitialPositive}
												/>
											</TableCell>
										</TableRow>
									);
								})
							) : (
								<TableRow>
									<TableCell colSpan={6} classes={{ root: classes.noDataCell }}>
										<Box
											display="flex"
											justifyContent="center"
											alignItems="center"
											gridGap="10px"
										>
											<NoDataIcon />
											<R id="shared_no_data_available" defaultValue="No data" />
										</Box>
									</TableCell>
								</TableRow>
							)}
						</TableBody>
					</Table>
				</ProgressContainer>
			</ExpansionPanel>
		</TableContainer>
	);
};

export default CryptocurrencyRates;
