import React from 'react';
import { WithdrawWidgetModal } from '@dev2t/react-ui-kit/src';
import { template } from '@templates';
import { userAccounts } from '@redux';
import { useSelector } from 'react-redux';
import { useAsyncPostRequest } from './useAsyncBackendRequest';
import { ThemeProvider } from '@material-ui/core';
import { originalTheme } from '@config/theme'
import { app } from '@redux';

const CommonWithdrawModuleModal = ({ onClose }) => {
	const post = useAsyncPostRequest();

	const currentTradeAccount = useSelector(userAccounts.selectors.currentTradeAccount);

	const postWithProxy = async (url, body) => {

		const req = {
			path: url,
			body: JSON.stringify(body),
		};

		const resp = await post(
			req,
			'Error while loading data for withraw');

		return {
			data: resp.ServiceResponseBody && JSON.parse(resp.ServiceResponseBody) || null,
			code: resp.ServiceResponseHttpCode,
		}
	};

	/* используем оригинальную тему, т.к. локальные изменения темы портят разметку. Убрать в рамках WEBCAB-8445 */
	return (
		<ThemeProvider theme={originalTheme}>
			<WithdrawWidgetModal
				onClose={onClose}
				performPostRequest={postWithProxy}
				selectedAccountTradeCode={currentTradeAccount.tradeCode}
				firmLogo={<template.firmLogo />}
				exitWidget={onClose}
				showSelectedAccountOnTopOfWithdrawForm
			/>
		</ThemeProvider>
	);
}

export default CommonWithdrawModuleModal;
